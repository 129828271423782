<template>
  <b-card
      class="game-edit-wrapper"
  >

    <!-- form -->
    <b-form class="mt-2" @submit.prevent="onSubmit">
<!--      <b-overlay
          :show="isLoading"
          rounded="sm"
      >-->
        <b-row>
          <b-col md="6">
            <b-form-group
                label="Title"
                label-for="game-edit-title"
                class="mb-2"
            >
              <b-form-input
                  id="game-edit-title"
                  v-model="gameData.name"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
                label="Type"
                label-for="game-edit-type"
                class="mb-2"
            >
              <v-select
                  id="game-edit-type"
                  v-model="gameData.type"
                  :options="typeOptions"
                  :reduce="val => val.value"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <div
                v-if="gameData.rounds"
            >
              <!-- Tab: Раунд Х -->
              <div :active="roundIndex === 0" v-for="(round, roundIndex)  in gameData.rounds"
                   :key="round.id"
              >
                <div>
                  <feather-icon
                      icon="FolderIcon"
                      size="16"
                      class="mr-0 mr-sm-50"
                  />
                  <span class="d-none d-sm-inline">{{ round.name }}</span>
                </div>
                <ul>
                  <b-list-group-item
                      v-for="(topic, topicIndex)  in round.topics"
                      :key="topic.id"
                      tag="li"
                  >
                    <div class="d-flex">
                      <div class="ml-25">
                        <b-card-text class="mb-0 topic-item">
                          <b-row>
                            <b-col md="12" cols="12">
                              <b-col md="2" cols="2">
                                <span v-if="topic.id" class="text-bold">{{ topic.name }}</span>
                                <span v-else>{{ topic.name }}</span>
                              </b-col>

                              <b-col md="8" cols="8">
                                <v-select
                                    :options="topicsList"
                                    :reduce="option => option.id"
                                    label="title"
                                    @input="(val) => setTopicTitle(topicIndex, val)"
                                />
                              </b-col>
                            </b-col>

                            <b-col md="12" cols="12">
                              <div
                                  v-for="question in topic.questions"
                                  :key="question.id"
                              >
                                <span class="text-bold">{{ question.price }}</span>
                                [ВОПРОС]

                              </div>
                            </b-col>
                          </b-row>


                        </b-card-text>
                      </div>
                    </div>
                  </b-list-group-item>
                </ul>
              </div>
            </div>
          </b-col>
          <b-col
              cols="12"
              class="mt-50"
          >
            <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                type="submit"
                class="mr-1"
                :disabled="isLoading"
            >
              <span v-if="isLoading">
                <b-spinner small/>
                Сохраняем...
              </span>
              <span v-else>
                Сохранить
              </span>
            </b-button>
          </b-col>
        </b-row>
<!--      </b-overlay>-->
    </b-form>
    <!--/ form -->
  </b-card>
</template>

<script>
import {
  BCard,
  BMedia,
  BAvatar,
  BCardText,
  BMediaAside,
  BMediaBody,
  BForm,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BImg,
  BFormFile,
  BLink,
  BButton,
  BTabs, BTab,
  BNavItem,
  BOverlay,
  BListGroupItem,
  BSpinner,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import {onUnmounted, ref} from '@vue/composition-api'
import router from "@/router";
import store from "@/store";
import gameStoreModule from "@/views/game/gameStoreModule";
import draggable from 'vuedraggable';
import StatisticCardHorizontal from '@core/components/statistics-cards/StatisticCardHorizontal.vue'

export default {
  components: {
    BCard,
    BMedia,
    BAvatar,
    BCardText,
    BMediaAside,
    BMediaBody,
    BForm,
    BLink,
    BImg,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormFile,
    vSelect,
    BTabs,
    BTab,
    BNavItem,
    BOverlay,
    BListGroupItem,
    draggable,
    StatisticCardHorizontal,
    BSpinner,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      typeOptions: [
        {
          value: 'rock',
          label: 'Рок',
        },
        {
          value: 'rusrock',
          label: 'Русский Рок',
        },
        {
          value: 'foreignrock',
          label: 'Зарубежный Рок',
        },
        {
          value: 'ugadai-rock',
          label: 'Угадай мелодию - Рок',
        },
        {
          value: 'ugadai-pop',
          label: 'Угадай мелодию - Поп',
        },
        {
          value: 'films',
          label: 'Фильмы',
        },
        {
          value: 'serials',
          label: 'Сериалы',
        },
        {
          value: 'football',
          label: 'Футбол',
        },
      ],
    }
  },
  setup() {
    const gameData = ref({
      "name": 'Empty game',
      "rounds": [
        {
          "name": "Раунд 1",
          "number": 1,
          "topics": [
            {
              "order": 1,
              "name": "Тема 1",
              "questions": [
                {
                  "id": null,
                  "order": 1,
                  "price": 100
                },
                {
                  "id": null,
                  "order": 2,
                  "price": 200
                },
                {
                  "id": null,
                  "order": 3,
                  "price": 300
                },
                {
                  "id": null,
                  "order": 4,
                  "price": 400
                },
                {
                  "id": null,
                  "order": 5,
                  "price": 500
                },
              ]
            },
            {
              "order": 2,
              "name": "Тема 2",
              "questions": [
                {
                  "id": null,
                  "order": 1,
                  "price": 100
                },
                {
                  "id": null,
                  "order": 2,
                  "price": 200
                },
                {
                  "id": null,
                  "order": 3,
                  "price": 300
                },
                {
                  "id": null,
                  "order": 4,
                  "price": 400
                },
                {
                  "id": null,
                  "order": 5,
                  "price": 500
                },
              ]
            },
            {
              "order": 3,
              "name": "Тема 3",
              "questions": [
                {
                  "id": null,
                  "order": 1,
                  "price": 100
                },
                {
                  "id": null,
                  "order": 2,
                  "price": 200
                },
                {
                  "id": null,
                  "order": 3,
                  "price": 300
                },
                {
                  "id": null,
                  "order": 4,
                  "price": 400
                },
                {
                  "id": null,
                  "order": 5,
                  "price": 500
                },
              ]
            },
            {
              "order": 4,
              "name": "Тема 4",
              "questions": [
                {
                  "id": null,
                  "order": 1,
                  "price": 100
                },
                {
                  "id": null,
                  "order": 2,
                  "price": 200
                },
                {
                  "id": null,
                  "order": 3,
                  "price": 300
                },
                {
                  "id": null,
                  "order": 4,
                  "price": 400
                },
                {
                  "id": null,
                  "order": 5,
                  "price": 500
                },
              ]
            },
            {
              "order": 5,
              "name": "Тема 5",
              "questions": [
                {
                  "id": null,
                  "order": 1,
                  "price": 100
                },
                {
                  "id": null,
                  "order": 2,
                  "price": 200
                },
                {
                  "id": null,
                  "order": 3,
                  "price": 300
                },
                {
                  "id": null,
                  "order": 4,
                  "price": 400
                },
                {
                  "id": null,
                  "order": 5,
                  "price": 500
                },
              ]
            },
            {
              "order": 6,
              "name": "Тема 6",
              "questions": [
                {
                  "id": null,
                  "order": 1,
                  "price": 100
                },
                {
                  "id": null,
                  "order": 2,
                  "price": 200
                },
                {
                  "id": null,
                  "order": 3,
                  "price": 300
                },
                {
                  "id": null,
                  "order": 4,
                  "price": 400
                },
                {
                  "id": null,
                  "order": 5,
                  "price": 500
                },
              ]
            },
          ]
        },
        {
          "name": "Раунд 2",
          "number": 1,
          "topics": [
            {
              "order": 1,
              "name": "Тема 1",
              "questions": [
                {
                  "id": null,
                  "order": 1,
                  "price": 100
                },
                {
                  "id": null,
                  "order": 2,
                  "price": 200
                },
                {
                  "id": null,
                  "order": 3,
                  "price": 300
                },
                {
                  "id": null,
                  "order": 4,
                  "price": 400
                },
                {
                  "id": null,
                  "order": 5,
                  "price": 500
                },
              ]
            },
            {
              "order": 2,
              "name": "Тема 2",
              "questions": [
                {
                  "id": null,
                  "order": 1,
                  "price": 100
                },
                {
                  "id": null,
                  "order": 2,
                  "price": 200
                },
                {
                  "id": null,
                  "order": 3,
                  "price": 300
                },
                {
                  "id": null,
                  "order": 4,
                  "price": 400
                },
                {
                  "id": null,
                  "order": 5,
                  "price": 500
                },
              ]
            },
            {
              "order": 3,
              "name": "Тема 3",
              "questions": [
                {
                  "id": null,
                  "order": 1,
                  "price": 100
                },
                {
                  "id": null,
                  "order": 2,
                  "price": 200
                },
                {
                  "id": null,
                  "order": 3,
                  "price": 300
                },
                {
                  "id": null,
                  "order": 4,
                  "price": 400
                },
                {
                  "id": null,
                  "order": 5,
                  "price": 500
                },
              ]
            },
            {
              "order": 4,
              "name": "Тема 4",
              "questions": [
                {
                  "id": null,
                  "order": 1,
                  "price": 100
                },
                {
                  "id": null,
                  "order": 2,
                  "price": 200
                },
                {
                  "id": null,
                  "order": 3,
                  "price": 300
                },
                {
                  "id": null,
                  "order": 4,
                  "price": 400
                },
                {
                  "id": null,
                  "order": 5,
                  "price": 500
                },
              ]
            },
            {
              "order": 5,
              "name": "Тема 5",
              "questions": [
                {
                  "id": null,
                  "order": 1,
                  "price": 100
                },
                {
                  "id": null,
                  "order": 2,
                  "price": 200
                },
                {
                  "id": null,
                  "order": 3,
                  "price": 300
                },
                {
                  "id": null,
                  "order": 4,
                  "price": 400
                },
                {
                  "id": null,
                  "order": 5,
                  "price": 500
                },
              ]
            },
            {
              "order": 6,
              "name": "Тема 6",
              "questions": [
                {
                  "id": null,
                  "order": 1,
                  "price": 100
                },
                {
                  "id": null,
                  "order": 2,
                  "price": 200
                },
                {
                  "id": null,
                  "order": 3,
                  "price": 300
                },
                {
                  "id": null,
                  "order": 4,
                  "price": 400
                },
                {
                  "id": null,
                  "order": 5,
                  "price": 500
                },
              ]
            },
          ]
        },
        {
          "name": "Раунд 3",
          "number": 1,
          "topics": [
            {
              "order": 1,
              "name": "Тема 1",
              "questions": [
                {
                  "id": null,
                  "order": 1,
                  "price": 100
                },
                {
                  "id": null,
                  "order": 2,
                  "price": 200
                },
                {
                  "id": null,
                  "order": 3,
                  "price": 300
                },
                {
                  "id": null,
                  "order": 4,
                  "price": 400
                },
                {
                  "id": null,
                  "order": 5,
                  "price": 500
                },
              ]
            },
            {
              "order": 2,
              "name": "Тема 2",
              "questions": [
                {
                  "id": null,
                  "order": 1,
                  "price": 100
                },
                {
                  "id": null,
                  "order": 2,
                  "price": 200
                },
                {
                  "id": null,
                  "order": 3,
                  "price": 300
                },
                {
                  "id": null,
                  "order": 4,
                  "price": 400
                },
                {
                  "id": null,
                  "order": 5,
                  "price": 500
                },
              ]
            },
            {
              "order": 3,
              "name": "Тема 3",
              "questions": [
                {
                  "id": null,
                  "order": 1,
                  "price": 100
                },
                {
                  "id": null,
                  "order": 2,
                  "price": 200
                },
                {
                  "id": null,
                  "order": 3,
                  "price": 300
                },
                {
                  "id": null,
                  "order": 4,
                  "price": 400
                },
                {
                  "id": null,
                  "order": 5,
                  "price": 500
                },
              ]
            },
            {
              "order": 4,
              "name": "Тема 4",
              "questions": [
                {
                  "id": null,
                  "order": 1,
                  "price": 100
                },
                {
                  "id": null,
                  "order": 2,
                  "price": 200
                },
                {
                  "id": null,
                  "order": 3,
                  "price": 300
                },
                {
                  "id": null,
                  "order": 4,
                  "price": 400
                },
                {
                  "id": null,
                  "order": 5,
                  "price": 500
                },
              ]
            },
            {
              "order": 5,
              "name": "Тема 5",
              "questions": [
                {
                  "id": null,
                  "order": 1,
                  "price": 100
                },
                {
                  "id": null,
                  "order": 2,
                  "price": 200
                },
                {
                  "id": null,
                  "order": 3,
                  "price": 300
                },
                {
                  "id": null,
                  "order": 4,
                  "price": 400
                },
                {
                  "id": null,
                  "order": 5,
                  "price": 500
                },
              ]
            },
            {
              "order": 6,
              "name": "Тема 6",
              "questions": [
                {
                  "id": null,
                  "order": 1,
                  "price": 100
                },
                {
                  "id": null,
                  "order": 2,
                  "price": 200
                },
                {
                  "id": null,
                  "order": 3,
                  "price": 300
                },
                {
                  "id": null,
                  "order": 4,
                  "price": 400
                },
                {
                  "id": null,
                  "order": 5,
                  "price": 500
                },
              ]
            },
          ]
        },
      ]
    })
    const isLoading = ref(false)
    const GAMES_STORE_MODULE_NAME = 'games'
    if (!store.hasModule(GAMES_STORE_MODULE_NAME)) store.registerModule(GAMES_STORE_MODULE_NAME, gameStoreModule)
    onUnmounted(() => {
      if (store.hasModule(GAMES_STORE_MODULE_NAME)) store.unregisterModule(GAMES_STORE_MODULE_NAME)
    })

    const topicsList = ref([])

    const newEmptyGame = function () {
      let rounds = 3
      let topics = 6
      let questions = 5
      let emptyGame = {}
      let round = {}
      let topic = {}
      let question = {}
      let currentRound = 0
      let currentTopic = 0
      let currentQuestion = 0
      let roundNumber = 1
      let topicNumber = 1
      let questionNumber = 1
      emptyGame.name = 'Empty game'
      emptyGame.rounds = []
      while (currentRound < rounds) {
        round = {}
        roundNumber = currentRound + 1
        round.name = `Раунд ${roundNumber}`
        round.number = roundNumber
        round.topics = []
        while (currentTopic < topics) {
          topic = {}
          topicNumber = currentTopic + 1
          topic.name = `Тема ${topicNumber}`
          topic.order = topicNumber
          topic.questions = []
          while (currentQuestion < questions) {
            question = {}
            questionNumber = currentQuestion + 1
            question.id = null
            question.order = questionNumber
            question.price = roundNumber * questionNumber * 100

            topic.questions.push(question)
            currentQuestion++
          }
          currentQuestion = 0
          round.topics.push(topic)
          currentTopic++
        }
        currentTopic = 0
        emptyGame.rounds.push(round)
        currentRound++
      }

      return emptyGame
    }

    const setTopicTitle = function (id, topicId) {
      console.log(id, topicId)

      let topic = topicsList[id]
      let gameTopic = gameData[topicId]

      console.log(topic, gameTopic)
    }

    const loadTopicsList = function () {
      store.dispatch('games/topicslist', {type: 'music', except: 123})
          .then(response => {
            topicsList.value = response.data.data
            isLoading.value = false
          })
          .catch(error => {
            /*if (error.response.status === 404) {
              gameData.value = undefined
            }*/
            isLoading.value = false
          })
    }

    loadTopicsList()

    const onSubmit = () => {
      isLoading.value = true
      store.dispatch('games/saveGame', {id: router.currentRoute.params.id, game: gameData.value})
          .then(response => {
            gameData.value = response.data.data
            isLoading.value = false
          }).catch(error => {
        isLoading.value = false
      })
    }

    return {
      gameData,
      onSubmit,
      topicsList,
      isLoading,
      setTopicTitle,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/quill.scss';
@import '@core/scss/vue/pages/page-blog.scss';

.topic-item {
  width: 1200px;
}

</style>
